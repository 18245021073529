import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import NoSleep from "nosleep.js";
import io from "socket.io-client";
import dayjs from "dayjs";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

import styles from "./index.module.scss";
const socketPromise = require("../../socket.io-promise").promise;
const noSleep = new NoSleep();

document.addEventListener(
  "click",
  function enableNoSleep() {
    document.removeEventListener("click", enableNoSleep, false);
    noSleep.enable();
  },
  false
);

// const streamId = "meshub";
// const ENDPOINT = `https://rtc-to-rtmp-server.meshstream.io?streamId=${streamId}`;

// const meshubSocket = io(generateSocketUrl({ streamId: "meshub" }), {
//   transports: ["websocket"],
// });
// meshubSocket.request = socketPromise(meshubSocket);

const ytRtmpUrl = "rtmp://x.rtmp.youtube.com/live2";
const fbRtmpUrl = "rtmps://live-api-s.facebook.com:443/rtmp";

const ytSocket = io(
  generateSocketUrl({ streamId: `yt-${Math.floor(Math.random() * 1000) + 1}` }),
  {
    transports: ["websocket"],
  }
);
ytSocket.request = socketPromise(ytSocket);

const fbSocket = io(
  generateSocketUrl({ streamId: `yt-${Math.floor(Math.random() * 1000) + 1}` }),
  {
    transports: ["websocket"],
  }
);

fbSocket.request = socketPromise(fbSocket);

let stream = null;
let ytRecorder = null;
let fbRecorder = null;
let ytBuffer = [];
let fbBuffer = [];
let ytIdCount = 0;
let fbIdCount = 0;

function generateSocketUrl({ streamId }) {
  return `https://rtc-to-rtmp-server.meshstream.io?streamId=${streamId}`;
}

// let isYtPublishing = false;
// let isFbPublishing = false;

async function ytDataAvailable(chunk) {
  if (ytSocket.connected === false) {
    ytBuffer.push({ id: ++ytIdCount, chunk });
    return;
  }

  try {
    while (ytBuffer.length > 0) {
      const firstChunk = ytBuffer.shift();
      await ytSocket.request("rtcToRtmp:dataChunk", firstChunk.chunk);
    }

    await ytSocket.request("rtcToRtmp:dataChunk", chunk);

    return;
  } catch (err) {
    ytBuffer.push(chunk);
  }
}

async function fbDataAvailable(chunk) {
  if (fbSocket.connected === false) {
    fbBuffer.push({ id: ++fbIdCount, chunk });
    return;
  }

  try {
    while (fbBuffer.length > 0) {
      const firstChunk = fbBuffer.shift();
      await fbSocket.request("rtcToRtmp:dataChunk", firstChunk.chunk);
    }

    await fbSocket.request("rtcToRtmp:dataChunk", chunk);

    return;
  } catch (err) {
    fbBuffer.push(chunk);
  }
}

const Home = () => {
  const localVideoRef = useRef();
  const audioRef = useRef();
  const { browserInfo } = useSelector((state) => state);
  const [byteCount, setByteCount] = useState(0);
  const [ytKey, setYtKey] = useState("qpzx-epu3-2596-zh33-e4v7");
  const [fbKey, setFbKey] = useState("FB-10158858777247768-0-AbwF56YMS-MLCmLL");
  const [selectedVideoInput, setSelectedVideoInput] = useState(null);
  const [videoInputDevices, setVideoInputDevices] = useState(null);
  const [isYtPublishing, setIsYtPublishing] = useState(false);
  const [isFbPublishing, setIsFbPublishing] = useState(false);

  useEffect(() => {
    ytSocket
      .on("ping", function (data) {
        ytSocket.emit("pong", { beat: 1 });
      })
      .on("ffmpegClosed", async ({ streamId, code }) => {
        console.log(" ffmpegClosed", streamId, code);
        console.error("ffmpegClosed", streamId, code);
        if (isYtPublishing && code === 1) {
          await stopYtRecording();
          console.log(" republish");
          console.info("republish");
          ytRtcToRtmp();
        }
      })
      .on("disconnect", (reason) => {
        audioRef.current.play();
        console.log("FB1 disconnect", reason);
        console.log("disconnect reason", reason);
        console.info(
          "FB1 disconnect",
          reason,
          dayjs().format("MM/DD HH:mm:ss")
        );
        console.info(
          "disconnect reason",
          reason,
          dayjs().format("MM/DD HH:mm:ss")
        );
        setByteCount(() => 0);
      })
      .on("reconnect", async () => {
        console.log("socket reconnect", dayjs().format("MM/DD HH:mm:ss"));
        console.info("socket reconnect", dayjs().format("MM/DD HH:mm:ss"));
        if (isYtPublishing) {
          console.log("republish", dayjs().format("MM/DD HH:mm:ss"));
          console.info("republish", dayjs().format("MM/DD HH:mm:ss"));
        }
      })
      .on("unauthorized", (data) => {
        console.log("unauthorized", data);
      })
      .on("rtcToRtmp:failed", (data) => {
        console.log("FB1 rtcToRtmp:failed", data);
        console.error("FB1 rtcToRtmp:failed", data);
      });

    fbSocket
      .on("ping", function (data) {
        fbSocket.emit("pong", { beat: 1 });
      })
      .on("ffmpegClosed", async ({ streamId, code }) => {
        console.log(" ffmpegClosed", streamId, code);
        console.error("ffmpegClosed", streamId, code);
        if (isFbPublishing && code === 1) {
          await stopFbRecording();
          console.log(" republish");
          console.info("republish");
          fbRtcToRtmp();
        }
      })
      .on("disconnect", (reason) => {
        audioRef.current.play();
        console.log("FB1 disconnect", reason);
        console.log("disconnect reason", reason);
        console.info(
          "FB1 disconnect",
          reason,
          dayjs().format("MM/DD HH:mm:ss")
        );
        console.info(
          "disconnect reason",
          reason,
          dayjs().format("MM/DD HH:mm:ss")
        );
        setByteCount(() => 0);
      })
      .on("reconnect", async () => {
        console.log("socket reconnect", dayjs().format("MM/DD HH:mm:ss"));
        console.info("socket reconnect", dayjs().format("MM/DD HH:mm:ss"));
        if (isFbPublishing) {
          console.log("republish", dayjs().format("MM/DD HH:mm:ss"));
          console.info("republish", dayjs().format("MM/DD HH:mm:ss"));
        }
      })
      .on("unauthorized", (data) => {
        console.log("unauthorized", data);
      })
      .on("rtcToRtmp:failed", (data) => {
        console.log("FB1 rtcToRtmp:failed", data);
        console.error("FB1 rtcToRtmp:failed", data);
      });
  }, []);

  useEffect(() => {
    if (localVideoRef.current && !stream) {
      listDevices({ kind: "videoinput" });
    }
  }, []);

  useEffect(() => {
    if (localVideoRef.current && selectedVideoInput) {
      getUserMedia();
    }
  }, [selectedVideoInput]);

  async function getUserMedia() {
    console.log("browserInfo.platform", browserInfo.platform);
    try {
      if (browserInfo.platform !== "desktop") {
        stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: 720,
            height: 1280,
            frameRate: { ideal: 30 },
            facingMode: "environment",
            deviceId: selectedVideoInput.deviceId,
          },
          audio: true,
        });
      } else {
        stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: { ideal: 1280 },
            height: { ideal: 720 },
            frameRate: { ideal: 30 },
            facingMode: "environment",
            deviceId: selectedVideoInput.deviceId,
          },
          audio: true,
        });
      }

      localVideoRef.current.srcObject = stream;
    } catch (error) {
      alert("getUserMedia error");
    }
  }

  const listDevices = async ({ kind }) => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      alert("不支援 enumerateDevices() .");
      return;
    }

    await navigator.mediaDevices.getUserMedia({
      video: true,
    });

    try {
      const devices = await navigator.mediaDevices.enumerateDevices();

      const specificDevices = devices
        .filter((device) => device.kind === kind)
        .map((item, index) => ({
          deviceId: item.deviceId,
          groupId: item.groupId,
          kind: item.kind,
          label: item.label,
        }));

      setSpecificDevices({ kind, specificDevices });
    } catch (error) {
      alert("UpdateWebcamDevices error: ", error);
    }
  };

  const setSpecificDevices = ({ kind, specificDevices }) => {
    switch (kind) {
      case "videoinput": {
        setVideoInputDevices(() => specificDevices);
        setSelectedVideoInput(() => specificDevices[0]);
        break;
      }

      default:
        break;
    }
  };

  const ytRtcToRtmp = async () => {
    const { success } = await ytSocket.request("rtcToRtmp:start", {
      rtmpUrl: `${ytRtmpUrl}/${ytKey}`,
      ffmpeg_params: `-i - -acodec aac -vcodec libx264 -preset ultrafast -b:v 2M -g 30 -filter:v fps=30 -f flv ${ytRtmpUrl}/${ytKey}`,
    });

    console.log("rtcToRtmp:start success", success);
    if (success) {
      ytStartRecording();
    }
  };

  const fbRtcToRtmp = async () => {
    const { success } = await fbSocket.request("rtcToRtmp:start", {
      rtmpUrl: `${fbRtmpUrl}/${fbKey}`,
      ffmpeg_params: `-i - -acodec aac -vcodec libx264 -preset ultrafast -b:v 2M -g 30 -filter:v fps=30 -f flv ${fbRtmpUrl}/${fbKey}`,
    });

    console.log("rtcToRtmp:start success", success);
    if (success) {
      fbStartRecording();
    }
  };

  const stopYtRecording = async () => {
    console.log("stop");

    ytRecorder.stop(); // must stop recorder before rtcToRtmp:stop
    await ytSocket.request("rtcToRtmp:stop");

    setIsYtPublishing(() => false);
    setByteCount(() => 0);
  };

  const stopFbRecording = async () => {
    console.log("stop");

    fbRecorder.stop(); // must stop recorder before rtcToRtmp:stop
    await fbSocket.request("rtcToRtmp:stop");

    setIsFbPublishing(() => false);
    setByteCount(() => 0);
  };

  const ytStartRecording = () => {
    setIsYtPublishing(() => true);

    if (browserInfo.os === "ios") {
      ytRecorder = new MediaRecorder(stream, {
        videoBitsPerSecond: 5000000,
        ignoreMutedMedia: true,
        mimeType: "video/mp4",
      });
    } else {
      ytRecorder = new MediaRecorder(stream, {
        videoBitsPerSecond: 5000000,
        ignoreMutedMedia: true,
        mimeType: "video/webm",
      });
    }

    ytRecorder.ondataavailable = async function (event) {
      console.log("ytSocket.connected", ytSocket.connected);

      if (event.data.size > 0) {
        ytDataAvailable(event.data);
      }
    };

    ytRecorder.onerror = function (event) {
      console.error("recorder.onerror", event.error.name);
    };

    ytRecorder.start(800); // 如果沒給 timeslice，則 ondataavailable 在 recorder.stop() 時才會觸發
  };

  const fbStartRecording = () => {
    setIsFbPublishing(() => true);

    if (browserInfo.os === "ios") {
      fbRecorder = new MediaRecorder(stream, {
        videoBitsPerSecond: 5000000,
        ignoreMutedMedia: true,
        mimeType: "video/mp4",
      });
    } else {
      fbRecorder = new MediaRecorder(stream, {
        videoBitsPerSecond: 5000000,
        ignoreMutedMedia: true,
        mimeType: "video/webm",
      });
    }

    fbRecorder.ondataavailable = async function (event) {
      console.log("fbSocket.connected", fbSocket.connected);

      if (event.data.size > 0) {
        fbDataAvailable(event.data);
      }
    };

    fbRecorder.onerror = function (event) {
      console.error("recorder.onerror", event.error.name);
    };

    fbRecorder.start(800); // 如果沒給 timeslice，則 ondataavailable 在 recorder.stop() 時才會觸發
  };

  // const disconnectSocket = () => {
  //   // ytSocket.disconnect();
  //   fbSocket.disconnect();
  //   console.log("disconnect");
  // };

  // const connectSocket = () => {
  //   fbSocket.connect();
  //   console.log("connect");
  // };

  return (
    <div className={styles.home}>
      {/* <div>
        <span>直播狀態: </span>
        <span className={`${byteCount ? styles.started : styles.stopped} `}>
          {byteCount ? "進行中" : "暫停中"}
        </span>
      </div>
      <span>Data chunk(byte): {byteCount}</span> */}
      <div>
        <video ref={localVideoRef} controls autoPlay muted playsInline />
      </div>
      <div>
        {/* <label htmlFor="meshubRtmpUrl">Meshub key</label>
        <InputText
          id="meshubKey"
          name="meshubKey"
          value={meshubKey}
          onChange={(e) => setMeshubKey(() => e.target.value)}
          autoComplete="off"
        /> */}
        {/* <Button
          type="button"
          label="推送"
          onClick={pushToMeshub}
          className="p-mt-2 primary"
        /> */}
      </div>
      <Dropdown
        id="resolution"
        name="resolution"
        value={selectedVideoInput}
        onChange={(e) => setSelectedVideoInput(e.value)}
        options={videoInputDevices}
        optionLabel="label"
        disabled={isYtPublishing || isFbPublishing}
      />
      <div>
        <label htmlFor="ytKey">YT key: </label>
        <InputText
          id="ytKey"
          name="ytKey"
          value={ytKey}
          onChange={(e) => setYtKey(() => e.target.value)}
          autoComplete="off"
        />
        <Button
          type="button"
          onClick={ytRtcToRtmp}
          label="推送到 youtube"
          className="p-mt-2 primary"
        />
        <Button
          type="button"
          onClick={stopYtRecording}
          label="停止推送"
          className="p-button-danger p-mt-2"
        />
      </div>
      <div>
        <label htmlFor="fbKey">FB key: </label>
        <InputText
          id="fbKey"
          name="fbKey"
          value={fbKey}
          onChange={(e) => setFbKey(() => e.target.value)}
          autoComplete="off"
        />
        <Button
          type="button"
          onClick={fbRtcToRtmp}
          label="推送到 FB"
          className="p-mt-2 primary"
        />
        <Button
          type="button"
          onClick={stopFbRecording}
          label="停止推送"
          className="p-button-danger p-mt-2"
        />
      </div>
      {/* <div>
        <Button
          type="button"
          onClick={disconnectSocket}
          label="disconnect"
          className="p-mt-2 p-button-warning"
        />
        <Button
          type="button"
          onClick={connectSocket}
          label="connect"
          className="p-mt-2 p-button-warning"
        />
      </div> */}
      <audio
        ref={audioRef}
        playsInline
        controls
        src="https://www.w3schools.com/tags/horse.mp3"
      />
    </div>
  );
};

export default Home;
